import React from 'react';

const Standard4 = () => {
  return (
    <div>
      <h1 style={{padding: "200px"}}>Composant Standard 4</h1>
      <p style={{padding: "0 200px"}}>Bienvenue dans la démo Standard 4 !</p>
    </div>
  );
};

export default Standard4;